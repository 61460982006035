import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c944fcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "password-validation row mt-1"
}
const _hoisted_2 = { class: "password-validation__icon" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tm_field, _mergeProps({
      validators: {
        'required': true,
        'min': 8,
        'regex': /(?=.*[0-9])/
      },
      placeholder: "Enter password",
      type: "password",
      name: "Password",
      "model-value": _ctx.modelValue,
      "hide-message": ""
    }, _ctx.$attrs, {
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validationVisible = true)),
      onBlur: _ctx.validationOut,
      "onUpdate:modelValue": _ctx.handleInput
    }), null, 16, ["validators", "model-value", "onBlur", "onUpdate:modelValue"]),
    (_ctx.validationVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationState, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "col-6 d-flex align-center font-weight-normal font-size-13 distinct--text lh-20"
            }, [
              _createElementVNode("div", _hoisted_2, [
                (!item.value && _ctx.validationFirstTime)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["password-validation__circle", {
              'password-validation__circle--light': _ctx.validationFirstTime
            }])
                    }, null, 2))
                  : (!item.value)
                    ? (_openBlock(), _createBlock(_component_tm_icon, {
                        key: 1,
                        class: "red--text",
                        name: "close"
                      }))
                    : (_openBlock(), _createBlock(_component_tm_icon, {
                        key: 2,
                        class: "success--text",
                        name: "check"
                      }))
              ]),
              _createElementVNode("span", {
                textContent: _toDisplayString(item.title)
              }, null, 8, _hoisted_3)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}