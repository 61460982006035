
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import SignupPassword from '@/components/shared/SignupPassword.vue'
import AuthWhiteBlock from '@/components/pages/auth/AuthWhiteBlock.vue'
import { Form as Validation } from 'vee-validate'
import type { FlagCountry } from '@/definitions/shared/types'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'

export default defineComponent({
  components: {
    PageAuth,
    SignupPassword,
    Validation,
    TmFormLine,
    AuthWhiteBlock,
    TmButton,
  },
  setup() {
    const firstName = ref<string>('')
    const lastName = ref<string>('')
    const password = ref<string>('')
    const email = ref<string>('johndoe@gmail.com')

    const country = ref<FlagCountry>(phoneCountryVariant[0])

    return {
      firstName,
      lastName,
      password,
      email,
      country,
    }
  },
})
