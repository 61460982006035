
import { defineComponent, ref } from 'vue'

type RuleType = {
  [key: string]: {
    title: string;
    value: boolean;
  }
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const validationVisible = ref(false)
    const validationFirstTime = ref(true)

    const validationState = ref<RuleType>({
      lengthValidation: {
        title: '8 to 48 characters',
        value: false,
      },
      numberValidation: {
        title: 'One number',
        value: false,
      },
      upperAndLowerCaseValidation: {
        title: 'Lower and uppercase',
        value: false,
      },
      specSymbolValidation: {
        title: 'One special character',
        value: false,
      },
    })

    const lengthValidation = (v: string) => {
      validationState.value.lengthValidation.value = v.length >= 8
    }

    const numberValidation = (v: string) => {
      validationState.value.numberValidation.value = /(?=.*[0-9])/.test(v)
    }

    const upperAndLowerCaseValidation = (v: string) => {
      validationState.value.upperAndLowerCaseValidation.value = /(?=.*[A-Z])/.test(v) && /(?=.*[a-z])/.test(v)
    }

    const specSymbolValidation = (v: string) => {
      validationState.value.specSymbolValidation.value = /(?=.*[^\w\s])/.test(v) || /(?=.*_)/.test(v) // or use /(?=.*[\W_]/.test(v) for allowing spaces
    }

    const validationOut = () => {
      validationFirstTime.value = false

      if (
        validationState.value.lengthValidation.value &&
        validationState.value.numberValidation.value &&
        validationState.value.upperAndLowerCaseValidation.value &&
        validationState.value.specSymbolValidation.value
      ) {
        validationVisible.value = false
      }
    }

    const handleInput = (e: any) => {
      context.emit('update:modelValue', e)
      lengthValidation(e)
      numberValidation(e)
      upperAndLowerCaseValidation(e)
      specSymbolValidation(e)
    }

    return {
      handleInput,
      validationState,
      validationVisible,
      validationFirstTime,
      validationOut,
    }
  },
})
